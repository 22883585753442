import type { ViewStateProps } from '@deck.gl/core/lib/deck'
import geoViewport from '@mapbox/geo-viewport'
import { filter, includes, uniq } from 'lodash-es'
import { INITIAL_VIEW_STATE } from 'src/pages/vmap/map/Map'
import { type BulkListVehicle } from 'src/utils/scooterBulk/types'

interface Bounds {
    minLat: number
    minLon: number
    maxLat: number
    maxLon: number
}

const MIN_ZOOM = 0
const MAX_ZOOM = 20
const TILE_SIZE = 512

export const getOptimisedViewState = (
    mapWrapper: HTMLDivElement | undefined,
    vehicles: BulkListVehicle[],
): ViewStateProps => {
    const locations = vehicles.map(vehicle => vehicle.location)
    const bounds = getBounds(locations)
    const wsen: [number, number, number, number] = [bounds.minLon, bounds.minLat, bounds.maxLon, bounds.maxLat]
    const dimensions = getDimensions(mapWrapper)
    // When only one vehicle displayed, use the vehicle location as center and set zoom level 20.
    const viewport =
        locations.length === 1
            ? { center: [locations[0][1], locations[0][0]], zoom: 20 }
            : geoViewport.viewport(wsen, dimensions, MIN_ZOOM, MAX_ZOOM, TILE_SIZE)

    const optimisedViewState: ViewStateProps = {
        ...INITIAL_VIEW_STATE,
        longitude: viewport.center[0],
        latitude: viewport.center[1],
        zoom: viewport.zoom,
    }

    return optimisedViewState
}

const getBounds = (locations: [number, number][]): Bounds => {
    if (locations.length > 0) {
        return locations.reduce(
            (prev, curr) => {
                const minLon = curr[1] < prev.minLon ? curr[1] : prev.minLon
                const minLat = curr[0] < prev.minLat ? curr[0] : prev.minLat
                const maxLon = curr[1] > prev.maxLon ? curr[1] : prev.maxLon
                const maxLat = curr[0] > prev.maxLat ? curr[0] : prev.maxLat

                const bounds: Bounds = {
                    minLat,
                    minLon,
                    maxLat,
                    maxLon,
                }

                return bounds
            },
            {
                minLon: 180,
                minLat: 90,
                maxLon: -180,
                maxLat: -90,
            } as Bounds,
        )
    }

    const bounds: Bounds = {
        minLat: 0,
        minLon: 0,
        maxLat: 0,
        maxLon: 0,
    }

    return bounds
}

const getDimensions = (mapWrapper: HTMLDivElement | undefined): [number, number] => {
    if (mapWrapper) {
        const { offsetWidth, offsetHeight } = mapWrapper

        return [offsetWidth, offsetHeight]
    }

    return [0, 0]
}

export const handleDuplicatedSearch = (searchedText: string): { uniqText: string[]; duplicatedText: string[] } => {
    const text = searchedText.split(',')
    const duplicatedText = filter(text, (value, index, iteratee) => includes(iteratee, value, index + 1))
    const uniqDuplicatedText = uniq(duplicatedText)
    const uniqText = uniq(text)

    return { uniqText: uniqText, duplicatedText: uniqDuplicatedText }
}
