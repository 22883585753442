import { forwardRef, type ReactNode } from 'react'
import styled from 'styled-components'
import * as Colors from 'src/ui-kit/colors/colors'
import RadioButtonOn from 'src/assets/images/ic_radio_on.svg'
import RadioButtonOff from 'src/assets/images/ic_radio_off.svg'
import { fontPrimary } from 'src/ui-kit/fonts/fontFamily'

const hover = () => `
    &:hover {
        cursor: pointer;
        background-color: ${Colors.mediumGrey};
    }
`

const INPUT_WIDTH = '250px'
export const SELECT_WIDTH = '250px'

interface Props<T> {
    onChange?: (value: any) => any
    onEnterKey?: () => void
    onBlur?: (e: React.FocusEvent<T>) => void
    label?: ReactNode
    required?: boolean
    placeholder?: string
    disabled?: boolean
    dataTestId?: string
    value?: any
    type?: string
    step?: string
    name?: string
    color?: string
    min?: string | number
    max?: string | number
    width?: string
    maxWidth?: string
    maxLength?: number
    row?: boolean
    marginTop?: string
    marginBottom?: string
    footnote?: string
    autoFocus?: boolean
}

const Input = forwardRef<HTMLInputElement, Props<HTMLInputElement>>((props, ref) => (
    <Wrapper $width={props.width} $row={props.row}>
        {props.label && (
            <Label>
                {props.label} {props.required && '*'}
            </Label>
        )}
        <InputWrapper $maxWidth={props.maxWidth}>
            <StyledInput
                ref={ref}
                $marginTop={props.marginTop}
                $marginBottom={props.marginBottom}
                data-testid={props.dataTestId}
                type={props.type ? props.type : 'text'}
                step={props.step}
                value={props.value}
                name={props.name}
                disabled={props.disabled}
                placeholder={props.placeholder && props.placeholder}
                required={props.required}
                onChange={props.onChange}
                onKeyUp={e => {
                    if (e.keyCode === 13) {
                        props.onEnterKey?.()
                    }
                }}
                onBlur={props.onBlur}
                $color={props.color}
                min={props.min}
                max={props.max}
                maxLength={props.maxLength}
                autoFocus={props.autoFocus}
            />
            {props.footnote && <Footnote>{props.footnote}</Footnote>}
        </InputWrapper>
    </Wrapper>
))
Input.displayName = 'Input'
export { Input }

interface CurrencyInputProps {
    onChange?: (value: any) => any
    label?: string
    required?: boolean
    placeholder?: string
    disabled?: boolean
    dataTestId?: string
    value?: any
    step?: string
    name?: string
    width?: string
    maxWidth?: string
    maxLength?: number
    row?: boolean
    currency: string
    min?: number
    max?: number
}

export const CurrencyInput = (props: CurrencyInputProps) => {
    return (
        <Wrapper $width={props.width} $row={props.row} $maxWidth={props.maxWidth}>
            {props.label && (
                <Label>
                    {props.label} {props.required && '*'}
                </Label>
            )}
            <InputWrapper $maxWidth={props.maxWidth}>
                <StyledInput
                    data-testid={props.dataTestId}
                    type='number'
                    step={props.step}
                    value={props.value}
                    name={props.name}
                    disabled={props.disabled}
                    placeholder={props.placeholder && props.placeholder}
                    required={props.required}
                    onChange={props.onChange}
                    min={props.min}
                    max={props.max}
                />
                <CurrencyLabel>{props.currency}</CurrencyLabel>
            </InputWrapper>
        </Wrapper>
    )
}

interface TextAreaProps extends Props<HTMLTextAreaElement> {
    rows?: number
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => (
    <Wrapper $row={props.row}>
        {props.label && (
            <Label>
                {props.label} {props.required && '*'}
            </Label>
        )}
        <StyledTextArea
            ref={ref}
            data-testid={props.dataTestId}
            rows={props.rows}
            name={props.name}
            value={props.value}
            disabled={props.disabled}
            placeholder={props.placeholder && props.placeholder}
            required={props.required}
            onChange={props.onChange}
            onBlur={props.onBlur}
            maxLength={props.maxLength}
        />
    </Wrapper>
))
TextArea.displayName = 'TextArea'
export { TextArea }

interface RadioButtonProps {
    onClick: () => void
    dataTestId: string
    checked: boolean
    label: string
}

export const RadioButton = ({ dataTestId, checked, onClick, label }: RadioButtonProps) => (
    <RadioButtonWrapper onClick={onClick}>
        <RadioButtonIcon data-testid={dataTestId} src={checked ? RadioButtonOn : RadioButtonOff} alt='' />
        <div>{label}</div>
    </RadioButtonWrapper>
)

export const StyledInput = styled.input<{ $marginBottom?: string; $marginTop?: string; $color?: string }>`
    border-radius: 4px;
    padding: 9px;
    color: ${props => (props.disabled ? Colors.grey : Colors.black)};
    background-color: ${props => (props.$color ? props.$color : Colors.lightGrey)};
    margin-bottom: ${props => (props.$marginBottom ? props.$marginBottom : '10px')};
    border: 1px solid transparent;
    ${fontPrimary}
    font-size: 14px;

    &:focus {
        outline: none;
        border: 1px solid ${Colors.grey};
        background-color: ${Colors.white};
    }
    ${props => props.$marginTop && `margin-top: ${props.$marginTop};`}
    ${props => !props.disabled && hover}
`

export const Label = styled.label`
    font-size: 14px;
    padding: 0 10px;
    color: ${Colors.black};
    margin: 0;
    margin-bottom: 10px;
`

const CurrencyLabel = styled.label`
    font-size: 14px;
    padding: 0 10px;
    color: ${Colors.black};
    margin: 0;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-20px, calc(-50% - 4px));
`

const Footnote = styled.div`
    font-size: 12px;
    padding-left: 10px;
`

const StyledTextArea = styled.textarea`
    border-radius: 4px;
    padding: 10px;
    color: ${props => (props.disabled ? Colors.grey : Colors.black)};
    background-color: ${Colors.lightGrey};
    margin-bottom: 10px;
    border: 1px solid transparent;
    ${fontPrimary}
    font-size: 14px;

    &:focus {
        outline: none;
        border: 1px solid ${Colors.grey};
        background-color: ${Colors.white};
    }
    ${props => !props.disabled && hover}
`

const Wrapper = styled.div<{ $width?: string; $row?: boolean; $maxWidth?: string }>`
    display: flex;
    width: ${props => (props.$width ? props.$width : '100%')};
    flex-direction: ${props => (props.$row ? 'row' : 'column')};
    ${props => props.$row && 'margin: 10px 0;'}
    ${props => props.$row && 'justify-content: space-between;'}
    ${props => props.$row && 'align-items: center;'}
    ${props =>
        props.$row &&
        `
        input {
            flex: 1;
            max-width: ${props.$maxWidth || INPUT_WIDTH};
        }
        textarea {
            flex: 1;
            max-width: ${props.$maxWidth || INPUT_WIDTH};
        }
    `}
    ${props =>
        props.$row &&
        `
        ${Label} {
            font-weight: 600;
            padding: 0;
        }
    `}
`

const RadioButtonWrapper = styled.div`
    margin-right: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
`

const RadioButtonIcon = styled.img`
    margin-right: 5px;
`

export const Form = styled.form<{ $maxWidth?: string }>`
    max-width: ${props => (props.$maxWidth ? props.$maxWidth : '700px')};
    margin: 0 auto;
    display: flex;
    flex-direction: column;
`

export const FormRow = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 0 -15px;
`

export const FormColumn = styled.div`
    padding: 0 15px;
    width: 50%;
`

const InputWrapper = styled.div<{ $maxWidth?: string }>`
    display: flex;
    flex-direction: column;
    flex: 2;
    max-width: ${props => props.$maxWidth || INPUT_WIDTH};
    position: relative;
`
